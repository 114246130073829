.code-editor {
  display: flex;
  background-color: #1e1e1e;
  color: #d4d4d4;
  font-family: 'Fira Code', monospace;
  font-size: 14px;
  border: 1px solid #333;
  border-radius: 5px;
  overflow: hidden;
  height: 100%; /* Ensures the container takes full height */
  max-height: 75vh; /* Limit the height */
}

.line-numbers {
  padding: 10px 5px; /* Padding to adjust the alignment */
  background-color: #252526;
  color: #858585;
  text-align: right;
  user-select: none;
  border-right: 1px solid #333;
  min-width: 40px;
  overflow: hidden; /* Ensure it hides overflow */
}

.line-number {
  height: 18px; /* Match this with the line height of the textarea */
  line-height: 18px;
  font-family: inherit;
  font-size: inherit;
}

.code-input {
  flex: 1;
  padding: 10px;
  background-color: transparent;
  color: inherit;
  border: none;
  outline: none;
  resize: none;
  white-space: pre;
  overflow: auto;
  caret-color: #ffffff;
  font-family: inherit;
  font-size: inherit;
  line-height: 18px; /* Ensure this matches the .line-number */
}

.code-input:focus {
  outline: none;
}
